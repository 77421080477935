import Service from '@/helpers/service'

export default {

    index() {
        return Service().get('import');
    }, 
    import_table(params) {
        return Service().post('import/import-table/', {params});
    },   
    import_release(params) {
        return Service().post('ReleaseImport', params);
    },    
    import_history() {
        return Service().get('import/import-history');
    }, 
}