<template>
    <div>
        <base-header class="pb-6">
            <div class="row align-items-center py-4">
                <div class="col-lg-6 col-7">
                    <h6 class="h2 text-white d-inline-block mb-0"></h6>
                </div>      
            </div>
        </base-header>
        <div class="container-fluid mt--6">
            <div class="card mb-4 mt--7">
                <div class="card-header">
                    <div class="row">
                        <div class="col-4">
                            <h3 class="mb-0">
                                <a href="#/report/import" class=" mr-3">
                                    <i class="fa fa-arrow-left"></i>
                                </a>
                                {{ tt('import_history') }}
                            </h3>
                        </div>
                    </div>
                </div>
                <div>
                    <el-table height="350px" class="table-responsive table-flush" header-row-class-name="thead-light" :data="table.data" v-if="!onLoad">
                        <el-table-column :label="tt('file_name')" :prop="tt('file_name')" min-width="200" sortable>
                            <template v-slot="{row}">
                                {{row.file_name}}
                            </template>
                        </el-table-column>
                        <el-table-column :label="tt('table_name')" :prop="tt('table_name')" min-width="200" sortable>
                            <template v-slot="{row}">
                                {{row.table_name}}
                            </template>
                        </el-table-column>
                        <el-table-column :label="tt('message')" :prop="tt('message')" min-width="200" sortable>
                            <template v-slot="{row}">
                                {{row.message}}
                            </template>
                        </el-table-column>
                        <el-table-column :label="tt('success')" :prop="tt('success')" min-width="200" sortable>
                            <template v-slot="{row}">
                                {{row.success}}
                            </template>
                        </el-table-column>
                        <el-table-column :label="tt('errors')" :prop="tt('errors')" min-width="200" sortable>
                            <template v-slot="{row}">
                                {{row.errors}}
                            </template>
                        </el-table-column>
                        <el-table-column :label="tt('date')" :prop="tt('date')" min-width="130" sortable>
                            <template v-slot="{row}">
                                {{row.create_at}}
                            </template>
                        </el-table-column>
                        <el-table-column label="" :prop="tt('date')" min-width="130" sortable>
                            <template v-slot="{row}">
                                <!-- <base-button type="danger" @click="log(row.id)">{{ tt('log') }}</base-button> -->
                                <a :href="'http://211d014de9b0.ngrok.io/smartcat_api_dev/public/'+'import/import-history/export-errors?import_history_id='+row.id+'&token='+token" class="btn btn-sm btn-danger">{{tt('log')}}</a>
                            </template>
                        </el-table-column>
                    </el-table>
                    <page-loading v-else/>
                </div>
                <div class="card-footer">
                </div>
            </div>
        </div>
        <modal :show.sync="form.show">
            <template slot="header">
                <h5 class="modal-title">{{form.title}}</h5>
            </template>
            <div>
                <label class="form-control-label">{{ tt('code') }} <span class="text-danger">*</span></label>
                <base-input :name="tt('code')" :placeholder="tt('code')" v-model="checkDuplicate.code" rules="required"></base-input>

                <label class="form-control-label">{{ tt('table') }} <span class="text-danger">*</span></label>
                <base-input :name="tt('table')" rules="required">
                    <el-select class="select-danger" v-model="checkDuplicate.table" placeholder="Choose Table">
                        <el-option class="select-danger" value="01" label="abbrevation" key="abbrevation"></el-option>
                        <el-option class="select-danger" value="01" label="account_assignment_group" key="account_assignment_group"></el-option>
                    </el-select>
                </base-input>

                <label class="form-control-label">{{ tt('column') }} <span class="text-danger">*</span></label>
                <base-input :name="tt('column')" rules="required">
                    <el-select class="select-danger" v-model="checkDuplicate.column" placeholder="Choose Column">
                        <el-option class="select-danger" value="01" label="abbrevation" key="abbrevation"></el-option>
                        <el-option class="select-danger" value="01" label="account_assignment_group" key="account_assignment_group"></el-option>
                    </el-select>
                </base-input>
            </div>
            <template slot="footer">
                <base-button type="secondary" @click="form.show = false">{{ tt('reset') }}</base-button>
                <base-button type="primary">
                    <span v-if="form.add">{{ tt('add') }}</span>
                    <span v-else>{{ tt('edit') }}</span>
                </base-button>
            </template>
        </modal>
    </div>
</template>
<script>
    import {mapState} from 'vuex'
    import Api from '@/helpers/api';
    import importReport from '@/services/report/import.service';
    
    export default {        
        data() {
            return {     
                onLoad: false,                
                form: {
                    add: true,
                    title: this.tt('add_table_column'),
                    show: false
                }, 
                table: {
                    data: []
                },        
                checkDuplicate: {
                    
                },
                token: localStorage.token
            }
        },
        computed: {
            ...mapState({
                authUserPermission :state => state.auth.userPermission
            }),
        },
        mounted() {
            this.get()
        },
        methods: {
            get() {
                let context = this;
                context.onLoad = true;
                Api(context, importReport.import_history()).onSuccess(function(response) {
                    context.table.data = response.data.data.history
                    console.log(response.data.data.history)
                }).onFinish(function() {
                    context.onLoad = false;
                }).call();
            },
            handleTableAction(command) {
                switch (command.action) {
                    case 'edit':
                            this.edit(command.data);
                        break;
                    case 'remove':
                            this.remove(command.data);
                        break;
                    default:
                        break;
                }
            },
            create() {
                this.form.add = true;
                this.form.title = this.tt('add_table_column');
                this.form.show = true;
            },
            edit(id) {
                this.form.add = false;
                this.form.title = this.tt('edit_table_column');
                this.form.show = true;
            },
            remove(id) {
                this.confirmDialog("Are you sure to delete this data?").then((result) => {
                    if (result.value) {
                        this.alertDialog('success', 'Successed delete data');
                    } else {
                        this.alertDialog('error', 'Failed delete data');
                    }
                })
            },
        }   
    };
</script>
<style></style>
